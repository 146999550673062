<template>
  <custom-table server-endpoint="api/logs/get" :page-size="50" :columns="columns" initial-sort-field="logging_time" />
</template>

<script>
import Table from "@/components/Table";

export default {
  name: "index",
  components: {
    "custom-table": Table
  },
  setup() {
    const columns = [
      {
        text: "LOGGING_TIME",
        field: "logging_time",
        type: "date"
      },
      {
        text: "USER",
        field: "user",
        filterable: true
      },
      {
        text: "IS_ACTION_SUCCESSFUL",
        field: "successful"
      },
      {
        text: "ACTION_CATEGORY",
        field: "category",
        filterable: true
      },
      {
        text: "ACTION_TYPE",
        field: "action",
        filterable: true
      },
      {
        text: "ACTION_TARGET_INDEX",
        field: "target",
        filterable: true,
        type: 'number'
      }
    ];
    return {
      columns
    }
  }
}
</script>

<style scoped>

</style>
